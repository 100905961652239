import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';

const DataPipelineVisualization = () => {
  // Define node coordinates and connections for better layout
  const nodes = {
    devices: [
      { id: 'polar', label: 'Polar Device', x: 1, y: 1 },
      { id: 'webapp', label: 'Web App', x: 6, y: 1 }
    ],
    data: [
      { id: 'rawData', label: 'Raw Data', x: 2, y: 1 },
      { id: 'metadata', label: 'Dataset Metadata', x: 3, y: 2 },
      { id: 'hrvFeatures', label: 'HRV Features', x: 2, y: 3 },
      { id: 'balanced', label: 'Balanced Dataset', x: 3, y: 4 },
      { id: 'baseline', label: 'Baseline Data', x: 4, y: 3 },
      { id: 'predictive', label: 'Predictive Models', x: 5, y: 3 }
    ],
    processes: [
      { id: 'preprocessing', label: 'Preprocessing', x: 2, y: 2 },
      { id: 'dataGen', label: 'Data Generation', x: 2, y: 4 },
      { id: 'modeling', label: 'Data Modeling', x: 4, y: 4 },
      { id: 'inputs', label: 'Inputs', x: 6, y: 2 },
      { id: 'predictions', label: 'Predictions', x: 6, y: 4 }
    ]
  };

  const connections = [
    { from: 'polar', to: 'rawData' },
    { from: 'rawData', to: 'preprocessing' },
    { from: 'preprocessing', to: 'hrvFeatures' },
    { from: 'rawData', to: 'metadata' },
    { from: 'hrvFeatures', to: 'metadata' },
    { from: 'hrvFeatures', to: 'dataGen' },
    { from: 'dataGen', to: 'balanced' },
    { from: 'balanced', to: 'baseline' },
    { from: 'balanced', to: 'modeling' },
    { from: 'baseline', to: 'modeling' },
    { from: 'modeling', to: 'predictive' },
    { from: 'webapp', to: 'inputs' },
    { from: 'inputs', to: 'predictive' },
    { from: 'predictive', to: 'predictions' }
  ];

  const findNodeCoords = (id) => {
    for (const type of Object.values(nodes)) {
      const node = type.find(n => n.id === id);
      if (node) return { x: node.x * 80 + 40, y: node.y * 60 };
    }
    return null;
  };

  return (
    <Card className="w-full max-w-5xl mx-auto bg-gradient-to-br from-blue-50 to-blue-100">
      <CardHeader className="bg-[#4361ee] text-white rounded-t-lg">
          <span>Data Pipeline Diagram</span>
          <span className="text-sm opacity-75">Last update: October 2024</span>
      </CardHeader>
      <CardContent className="p-6">
        <div className="relative w-full aspect-[16/9] bg-white rounded-lg shadow-inner p-4">
          <svg className="w-full h-full" viewBox="0 0 600 400">
            {/* Server container */}
            <rect
              x="40"
              y="20"
              width="520"
              height="340"
              rx="15"
              className="fill-blue-50 stroke-blue-200"
              strokeWidth="2"
            />
            <text x="60" y="50" className="text-sm fill-[#4361ee] font-semibold">Server</text>

            {/* Draw connection lines */}
            {connections.map((conn, idx) => {
              const from = findNodeCoords(conn.from);
              const to = findNodeCoords(conn.to);
              if (!from || !to) return null;
              
              return (
                <path
                  key={`conn-${idx}`}
                  d={`M ${from.x} ${from.y} C ${(from.x + to.x)/2} ${from.y}, ${(from.x + to.x)/2} ${to.y}, ${to.x} ${to.y}`}
                  fill="none"
                  className="stroke-blue-200"
                  strokeWidth="2"
                  strokeDasharray="4 4"
                />
              );
            })}

            {/* Draw nodes */}
            {Object.entries(nodes).map(([type, items]) => (
              items.map(node => (
                <g key={node.id} transform={`translate(${node.x * 80 + 40}, ${node.y * 60})`}>
                  <rect
                    x="-50"
                    y="-20"
                    width="100"
                    height="40"
                    rx="8"
                    className={
                      type === 'devices' 
                        ? 'fill-[#4361ee] stroke-[#3451dd]'
                        : type === 'data'
                        ? 'fill-blue-100 stroke-blue-200'
                        : 'fill-white stroke-blue-200'
                    }
                    strokeWidth="2"
                  />
                  <text
                    className={`text-xs ${
                      type === 'devices' ? 'fill-white' : 'fill-blue-900'
                    } font-medium text-center`}
                    textAnchor="middle"
                    dy=".3em"
                  >
                    {node.label}
                  </text>
                </g>
              ))
            ))}
          </svg>
        </div>

        {/* Legend */}
        <div className="flex gap-6 mt-6 justify-center items-center text-sm">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-[#4361ee] rounded mr-2"></div>
            <span className="text-gray-700">Devices</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-blue-100 rounded mr-2 border border-blue-200"></div>
            <span className="text-gray-700">Data</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-white rounded mr-2 border border-blue-200"></div>
            <span className="text-gray-700">Processes</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default DataPipelineVisualization;