import React, { useState } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import { logEvent } from "firebase/analytics";
import { analytics } from './index.js';
import HRVSingleRecordingForm from './HRVSingleRecordingForm';
import FeedbackDialog from './Live/FeedbackDialog';
import OpenHRVFAQ from './OpenHRVFAQ';
import { Box, Button, Typography, Container, Card, CardContent } from '@mui/material';

const DemoPage: React.FC = () => {
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);

  logEvent(analytics, 'Demo page visited');

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  return (
    <div className="container">
    <Container>
      <Typography variant="h2" align="center" gutterBottom>
        Open HRV
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" paragraph>
        Free online Heart Rate Variability Analyzer
      </Typography>

        <Card sx={{ mb: 4, p: 2, width: '100%' }}>
          <CardContent>
            <Typography variant="h6">Integrate with your wearable device</Typography>
            <Typography paragraph>
              Unlock the power of AI-driven Heart Rate Variability analysis by seamlessly integrating your wearable device with Open HRV.
            </Typography>
            <Button component={Link} to="/wearables-we-support" variant="contained" color="primary">
              Learn More
            </Button>
          </CardContent>
        </Card>

        <Card sx={{ mb: 4, p: 2, width: '100%' }}>
          <CardContent>
            <Typography variant="h6">Help us improve</Typography>
            <Typography paragraph>
              Tell us you request regarding the HRV analytics or any other feature you would like to see in the app.
            </Typography>
            <Button variant="contained" onClick={handleFeedbackOpen}>
              Leave Feedback
            </Button>
          </CardContent>
        </Card>

        <Card sx={{ mb: 4, p: 2, width: '100%' }}>
          <CardContent>
            <Typography variant="h6">How it works:</Typography>
            <ol>
              <li>Record data from your wearable device</li>
              <li>Extract data as a supported file format</li>
              <li>Upload the file using the form below</li>
              <li>Choose proper parameters</li>
              <li>Click "Calculate" to see your personalized HRV report</li>
            </ol>
            <Typography paragraph>
              Supported file formats: .csv, .edf (European Data Format), .acq (AcqKnowledge), .txt (text file), .json, .xml, .mat (Matlab)
            </Typography>
            <Typography paragraph>Please reach us if you're interested in more options.</Typography>
          </CardContent>
        </Card>

        <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card sx={{ mb: 4, p: 2, width: '100%' }}>
          <CardContent>
            <Typography variant="h6">Try our app with a sample data:</Typography>
            <Button
              href="https://drive.google.com/uc?export=download&id=1JvW9vTj2RQaRrvFm0BLVtJ0uSEU3c3rG"
              download
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Download Sample Dataset
            </Button>
          </CardContent>
        </Card>

        <HRVSingleRecordingForm />

        <Box sx={{ my: 4, width: '100%' }}>
          <Typography variant="h6">Sample HRV Analysis Results</Typography>
          {/* Add charts or graphs for visual representation */}
        </Box>

        <Box sx={{ my: 4, width: '100%' }}>
          <Typography variant="h6">Frequently Asked Questions</Typography>
          <OpenHRVFAQ />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, my: 2 }}>
          <Button component={Link} to="/science" variant="outlined">
            Science
          </Button>
          <Button component={Link} to="/ketamine" variant="outlined">
            Ketamine
          </Button>
          <Button component={Link} to="/about" variant="outlined">
            About Us
          </Button>
        </Box>

        <Button variant="contained" color="secondary" onClick={handleFeedbackOpen} sx={{ mt: 4 }}>
          Leave Feedback
        </Button>

        <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
      </Box>
    </Container>
    </div>
  );
};

export default DemoPage;
