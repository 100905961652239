import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Button,
  Container,
  Typography,
  Box,
  Toolbar,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Assuming these components are updated to use Material-UI as well
import FormComponent from './SubscribeForm';
import OpenHRVFAQ from './OpenHRVFAQ';

// Import the background image
import heroBackground from './images/hrv-hero.png';
import { ClassNames } from '@emotion/react';

const StyledHero = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '90px 20px',
  width: '100%',
  height: '80vh',
  backgroundImage: `url(${heroBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: '20px',
  backgroundColor: '#e6f7ff',
  width: '100%',
}));

const DemoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px',
  padding: '20px',
}));

const DemoItem = styled(Card)(({ theme }) => ({
  width: '300px',
  borderRadius: '10px',
  padding: '15px',
  textAlign: 'center',
  backgroundColor: '#fff',
  height: '100px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const SurveySection = styled(Box)(({ theme }) => ({
  padding: '20px',
  textAlign: 'center',
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const demoItems = [
  { title: 'HRV Calculation Demo', description: 'Live Demo to preprocess your HRV data', link: '/hrv-calculation-demo' },
  { title: 'Flutter HRV', description: 'Flutter library to analyze HRV from different devices', link: 'https://pub.dev/packages/flutter_hrv' },
  { title: 'SDK', description: 'Demo of SDK based on our HRV engine', link: 'https://github.com/Open-HRV/Open-HRV-SDK' },
];

function HomePage() {
  return (
    <div className='container'>
      <main>
      <StyledHero>
          <Container maxWidth="md">
            <Typography 
              component="h1" 
              variant="h1" 
              color="common.white" 
              gutterBottom
              sx={{ 
                fontSize: { xs: '3rem', sm: '4rem', md: '5rem' },
                fontWeight: 'bold',
                textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
              }}
            >
              Open HRV
            </Typography>
            <Typography 
              variant="h4" 
              color="common.white" 
              paragraph
              sx={{ 
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                fontWeight: 'medium',
                textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
              }}
            >
              Understand Your Heart's Rate Variability in Minutes
            </Typography>
            <Typography 
              variant="h6" 
              color="common.white" 
              paragraph
              sx={{ 
                fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                maxWidth: '800px',
                margin: '0 auto',
                textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
              }}
            >
              Get 50+ personalized heart health insights with our easy-to-use analytics. 
              Perfect for individuals and researchers.
            </Typography>
            <Box sx={{ mt: 6 }}>
              <StyledButton 
                variant="contained" 
                color="primary" 
                component={RouterLink} 
                to="/hrv-calculation-demo"
                size="large"
                sx={{ fontSize: '1.25rem', py: 1.5, px: 4 }}
              >
                Try It Now!
              </StyledButton>
              <StyledButton 
                variant="contained" 
                color="primary" 
                component={RouterLink} 
                to="/wearables-we-support"
                size="large"
                sx={{ fontSize: '1.25rem', py: 1.5, px: 4 }}
              >
                New Integration!
              </StyledButton>
            </Box>
          </Container>
        </StyledHero>

        <Container maxWidth="lg">
          <Box sx={{ my: 4 }}>
            <FormComponent />
          </Box>

          <Typography variant="h4" align="center" gutterBottom>
            Check out our demos:
          </Typography>
          <DemoGrid container>
            {demoItems.map((demo, index) => (
              <Grid item key={index}>
                <DemoItem>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {demo.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {demo.description}
                    </Typography>
                    <Button size="small" component={RouterLink} to={demo.link}>
                      Learn More
                    </Button>
                  </CardContent>
                </DemoItem>
              </Grid>
            ))}
          </DemoGrid>

          <InfoSection>
            <Typography variant="body1" align="center">
              <Button 
                component="a"
                href="https://medium.com/@przemekmarciniak/what-is-heart-rate-variability-and-how-we-could-use-it-dbe46e8c2fe6"
                target="_blank"
                rel="noopener noreferrer"
                variant="text"
              >
                Read article: What is Heart Rate Variability
              </Button>
            </Typography>
          </InfoSection>

          <Box sx={{ my: 4 }}>
            <OpenHRVFAQ />
          </Box>

          <SurveySection>
            <Typography variant="body1" gutterBottom>Please help us improve and take a</Typography>
            <Button 
              variant="contained" 
              color="primary" 
              href="https://s.surveyplanet.com/64c8c7b6635d1581bfaef67d" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ mt: 2 }}
            >
              Survey
            </Button>
          </SurveySection>
        </Container>
      </main>
    </div>
  );
}

export default HomePage;