import React from 'react';

interface BluetoothConnectModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConnect: () => void;
}

const BluetoothConnectModal: React.FC<BluetoothConnectModalProps> = ({
  isOpen,
  onClose,
  onConnect,
}) => {
  if (!isOpen) return null;

  return (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="relative w-11/12 max-w-lg p-6 bg-white rounded-lg shadow-lg">
      <button
        onClick={onClose}
        className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
        aria-label="Close"
      >
        Close
      </button>
      <div className="text-center">
        <h2 className="mb-4 text-xl font-semibold text-gray-700">
          Connect to a Bluetooth Device
        </h2>
        <div className="mt-6 text-left text-sm text-gray-600">
          <h3 className="font-semibold">For Google Chrome users:</h3>
          <ol className="list-decimal ml-6 mb-4">
            <li>Launch the Google Chrome browser.</li>
            <li>Type <code>chrome://flags</code> into the address bar.</li>
            <li>Hit Enter to access the Flags menu.</li>
            <li>Search for "Experimental Web Platform features".</li>
            <li>Set the value of the drop-down menu to <strong>Enabled</strong>.</li>
            <li>Restart your browser.</li>
          </ol>
          <h3 className="font-semibold">For Microsoft Edge users:</h3>
          <ol className="list-decimal ml-6">
            <li>Launch the Microsoft Edge browser.</li>
            <li>Type <code>edge://flags</code> into the address bar.</li>
            <li>Hit Enter to access the Flags menu.</li>
            <li>Search for "Experimental Web Platform features".</li>
            <li>Set the value of the drop-down menu to <strong>Enabled</strong>.</li>
            <li>Restart your browser.</li>
          </ol>
        </div>
        <p className="mb-4 text-gray-600">
          Click "Connect" to find and pair with a compatible Bluetooth heart rate device through your browser.
        </p>
        <button
          onClick={onConnect}
          className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
        >
          Connect
        </button>
      </div>
    </div>
  </div>

  );
};

export default BluetoothConnectModal;
