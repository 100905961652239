import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

type HRVResult = {
  segment: number;
  data: {
    HRV_MeanNN: number;
    HRV_SDNN: number;
    HRV_RMSSD: number;
    HRV_pNN50: number;
    HRV_LFHF: number;
    HRV_HF: number;
    HRV_LF: number;
    HRV_SD1: number;
    HRV_SD2: number;
    HRV_CSI: number;
    HRV_CVI: number;
  };
};

interface HRVVisualizationProps {
  results: HRVResult[];
}

const HRVVisualization: React.FC<HRVVisualizationProps> = ({ results }) => {
  const segments = results.map((result) => `Segment ${result.segment}`);
  const meanNN = results.map((result) => result.data.HRV_MeanNN);
  const sdnn = results.map((result) => result.data.HRV_SDNN);
  const rmssd = results.map((result) => result.data.HRV_RMSSD);
  const lf = results.map((result) => result.data.HRV_LF);
  const hf = results.map((result) => result.data.HRV_HF);
  const lfHfRatio = results.map((result) => result.data.HRV_LFHF);

  const barChartData = {
    labels: segments,
    datasets: [
      {
        label: 'Mean NN (ms)',
        data: meanNN,
        borderWidth: 1,
      },
      {
        label: 'SDNN (ms)',
        data: sdnn,
        borderWidth: 1,
      },
      {
        label: 'RMSSD (ms)',
        data: rmssd,
        borderWidth: 1,
      },
    ],
  };

  const lineChartData = {
    labels: segments,
    datasets: [
      {
        label: 'LF (ms²)',
        data: lf,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
      {
        label: 'HF (ms²)',
        data: hf,
        fill: false,
        borderColor: 'rgba(153,102,255,1)',
        tension: 0.1,
      },
      {
        label: 'LF/HF Ratio',
        data: lfHfRatio,
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        tension: 0.1,
      },
    ],
  };

  return (
    <div>
      <h2>HRV Results Visualization</h2>
      <div style={{ width: '100%', maxWidth: '600px', margin: 'auto' }}>
        <h3>Time-Domain Metrics</h3>
        <Bar data={barChartData} />
      </div>
      <div style={{ width: '100%', maxWidth: '600px', margin: 'auto', marginTop: '2rem' }}>
        <h3>Frequency-Domain Metrics</h3>
        <Line data={lineChartData} />
      </div>
    </div>
  );
};

const convertResult = async (data: any): Promise<HRVResult[]>  => {
    if (data.downloadLink) {
      try {
        // Fetch data from the download link
        const response = await fetch(data.downloadLink);
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.statusText}`);
        }
  
        const fetchedData = await response.json(); // Assuming the response is JSON
        console.log('Fetched Data:', fetchedData);
  
        // Recursively call convertResult with the fetched data
        return convertResult(fetchedData);
      } catch (error) {
        console.error('Error fetching data from download link:', error);
        return [];
      }
    }
  
    console.log('Processing Data:', data);
    console.log(data);
    if (Array.isArray(data)) {
      // If it's an array, map directly
      return data.map((segment) => ({
        segment: segment.segment || 0, // Use a default value if "segment" is missing
        data: {
          HRV_MeanNN: segment.data.HRV_MeanNN,
          HRV_SDNN: segment.data.HRV_SDNN,
          HRV_RMSSD: segment.data.HRV_RMSSD,
          HRV_pNN50: segment.data.HRV_pNN50,
          HRV_LFHF: segment.data.HRV_LFHF,
          HRV_HF: segment.data.HRV_HF,
          HRV_LF: segment.data.HRV_LF,
          HRV_SD1: segment.data.HRV_SD1,
          HRV_SD2: segment.data.HRV_SD2,
          HRV_CSI: segment.data.HRV_CSI,
          HRV_CVI: segment.data.HRV_CVI,
        },
      }));
    } else if (typeof data === 'object' && data !== null) {
      // If it's an object, process its keys
      return Object.entries(data).map(([key, value]: [string, any]) => ({
        segment: parseInt(key, 10), // Convert the key to a number
        data: {
          HRV_MeanNN: value.HRV_MeanNN,
          HRV_SDNN: value.HRV_SDNN,
          HRV_RMSSD: value.HRV_RMSSD,
          HRV_pNN50: value.HRV_pNN50,
          HRV_LFHF: value.HRV_LFHF,
          HRV_HF: value.HRV_HF,
          HRV_LF: value.HRV_LF,
          HRV_SD1: value.HRV_SD1,
          HRV_SD2: value.HRV_SD2,
          HRV_CSI: value.HRV_CSI,
          HRV_CVI: value.HRV_CVI,
        },
      }));
    } else {
      console.error('Unsupported data format for HRV results.');
      return [];
    }
  };

export { HRVVisualization, convertResult };
export type { HRVResult };
