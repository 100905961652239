import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { logEvent } from "firebase/analytics";
import { analytics } from '../index.js';
import { scaleLinear } from 'd3-scale';
import FeedbackDialog from './FeedbackDialog';
import { calculateRMSSD, calculateSDNN, calculateStressIndex, calculateHeartRate, calculateReadiness, calculateSD1SD2Ratio } from './MetricsCalculator.js';
import BluetoothConnectModal from './BluetoothConnectModal';

import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InfoIcon from '@mui/icons-material/Info';

const SERVICE_UUID = 'heart_rate'; // Use standard service name
const CHARACTERISTIC_UUID = 'heart_rate_measurement'; // Use standard characteristic name

function HeartRateMonitor () {
  const [device, setDevice] = useState(null);
  const [server, setServer] = useState(null);
  const [hrCharacteristic, setHrCharacteristic] = useState(null);
  const [rrIntervals, setRrIntervals] = useState([]);
  const [isBluetoothModalOpen, setBluetoothModalOpen] = useState(false); // Modal state for Bluetooth instructions
  const [metrics, setMetrics] = useState({
    rmssd: null,
    sdnn: null,
    stressIndex: null,
    heartRate: null,
    readiness: null,
    sd1sd2Ratio: null,
  });
  const [metricsHistory, setMetricsHistory] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('Not Connected'); 
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  logEvent(analytics, 'Live HRV page visited');

  const connectToDevice = async () => {
    try {
      logEvent(analytics, 'Trying to connect to device');
      const options = {
        acceptAllDevices: true,
        optionalServices: [
          'heart_rate', // Heart Rate Service UUID
        ],
      };
      const device = await navigator.bluetooth.requestDevice(options);
      console.log('Device:', device);
      setConnectionStatus('Connecting to ' + device.name);
      logEvent(analytics, 'Device found: ' + device.name);
      const server = await device.gatt.connect();
      console.log('Server:', server);
      const service = await server.getPrimaryService(SERVICE_UUID);
      console.log('Service:', service);
      const characteristic = await service.getCharacteristic(CHARACTERISTIC_UUID);
      console.log('Characteristic:', characteristic);
      setDevice(device);
      setServer(server);
      setHrCharacteristic(characteristic);
      logEvent(analytics, 'Connected to device' + device.name);
    } catch (error) {
      console.error('Failed to connect: ' + error);
      logEvent(analytics, 'Failed to connect to device' + device + " " + error);
    }
  };

  const formatTime = (tick) => {
    const date = new Date(tick);
    return date.toLocaleTimeString();
  };

  useEffect(() => {
    if (hrCharacteristic) {
      hrCharacteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
      hrCharacteristic.startNotifications();

      return () => {
        hrCharacteristic.removeEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
        hrCharacteristic.stopNotifications();
      };
    }
  }, [hrCharacteristic]);

  const handleCharacteristicValueChanged = (event) => {
    const value = event.target.value;
    processHRVData(value);
  };

  const processHRVData = (value) => {
    const flags = value.getUint8(0);
    let index = 1;
    let heartRate;

    if (flags & 0x01) {
      heartRate = value.getUint16(index, true);
      index += 2;
    } else {
      heartRate = value.getUint8(index);
      index += 1;
    }

    if (flags & 0x10) {
      const rrIntervalsTemp = [];
      while (index + 1 < value.byteLength) {
        let rrInterval = value.getUint16(index, true) / 1024;
        index += 2;
        if (rrInterval >= 0.3 && rrInterval <= 2.0) {
          rrIntervalsTemp.push(rrInterval);
        }
      }
      setRrIntervals((prevIntervals) => {
        const updatedIntervals = [...prevIntervals, ...rrIntervalsTemp];
        return updatedIntervals.slice(-120);
      });
    }
  };

  useEffect(() => {
    if (rrIntervals.length > 15) {
      calculateMetrics();
      setConnectionStatus('Recording ' + device.name);
    }
    if (device){ 
      setConnectionStatus('Calibrating ' + device.name);
    }
  }, [rrIntervals]);

  const calculateMetrics = () => {
    const rmssd = calculateRMSSD(rrIntervals);
    const sdnn = calculateSDNN(rrIntervals);
    const stressIndex = calculateStressIndex(rrIntervals);
    const heartRate = calculateHeartRate(rrIntervals);
    const readiness = calculateReadiness(stressIndex, heartRate);
    const sd1sd2Ratio = calculateSD1SD2Ratio(rrIntervals);
  
    // Define theoretical or observed min and max values for each metric
    const rmssdScale = scaleLinear().domain([0, 200]).range([0, 100]);
    const sdnnScale = scaleLinear().domain([0, 150]).range([0, 100]);
    const stressIndexScale = scaleLinear().domain([0, 20]).range([0, 100]);
    const heartRateScale = scaleLinear().domain([40, 180]).range([0, 100]);
    const readinessScale = scaleLinear().domain([0, 100]).range([0, 100]);
    const sd1sd2RatioScale = scaleLinear().domain([0, 20]).range([0, 100]);

  
    // Normalize metrics
    const normalizedRmssd = rmssd;
    const normalizedSdnn = sdnn;
    const normalizedStressIndex = Math.min(stressIndexScale(stressIndex), 100);
    const normalizedHeartRate = heartRate;
    const normalizedReadiness = readinessScale(readiness);
    const normalizedSd1sd2Ratio = Math.min(sd1sd2RatioScale(sd1sd2Ratio), 100);
    
    setMetrics({
      rmssd: normalizedRmssd,
      sdnn: normalizedSdnn,
      stressIndex: normalizedStressIndex,
      heartRate: normalizedHeartRate,
      readiness: normalizedReadiness,
      sd1sd2Ratio: normalizedSd1sd2Ratio,
    });
  
    const timestamp = Date.now();
    setMetricsHistory((prevHistory) => {
      // Add the new metric data point
      let newHistory = [
        ...prevHistory,
        {
          timestamp,
          rmssd: normalizedRmssd,
          sdnn: normalizedSdnn,
          stressIndex: normalizedStressIndex,
          heartRate: normalizedHeartRate,
          readiness: normalizedReadiness,
          sd1sd2Ratio: normalizedSd1sd2Ratio,
        },
      ];
  
      // Keep only the last 5 minutes of data
      const fiveMinutesAgo = timestamp - 5 * 60 * 1000;
      return newHistory.filter((dataPoint) => dataPoint.timestamp >= fiveMinutesAgo);
    });
  };
    const handleFeedbackOpen = () => {
      setFeedbackOpen(true);
    };

    const handleFeedbackClose = () => {
      setFeedbackOpen(false);
    };

  return (
    <div className='container'>
      <Container maxWidth="md">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
            Heart Rate Monitor
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            We are supporting most of the wearables that are compatible with the Web Bluetooth API. Try it now!
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={connectToDevice}
              >
                Connect to Device
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<FeedbackIcon />}
                onClick={handleFeedbackOpen}
              >
                Feedback
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<InfoIcon />}
                onClick={() => setBluetoothModalOpen(true)}
              >
                Instructions
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* Bluetooth Connection Modal */}
        <BluetoothConnectModal
          isOpen={isBluetoothModalOpen}
          onClose={() => setBluetoothModalOpen(false)}
          onConnect={connectToDevice}
        />
        {/* Feedback Dialog */}
        <FeedbackDialog open={feedbackOpen} onClose={handleFeedbackClose} />
        <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Metrics:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Connection Status:</strong> {connectionStatus}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>RMSSD:</strong> {metrics.rmssd ? metrics.rmssd.toFixed(2) : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>SDNN:</strong> {metrics.sdnn ? metrics.sdnn.toFixed(2) : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>Stress Index:</strong> {metrics.stressIndex ? metrics.stressIndex.toFixed(2) : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>Heart Rate:</strong> {metrics.heartRate ? metrics.heartRate.toFixed(2) : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>Readiness:</strong> {metrics.readiness ? metrics.readiness.toFixed(2) : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1">
                <strong>SD2/Sd2:</strong> {metrics.sd1sd2Ratio ? metrics.sd1sd2Ratio : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            5-Minute Metrics Trend
          </Typography>
          <Box sx={{ overflowX: 'auto' }}>
          <ResponsiveContainer width="100%" height={isMobile ? 300 : 400}>
      <AreaChart
        data={metricsHistory}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorStressIndex" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorReadiness" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#0088FE" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorSd1sd2Ratio" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis
          dataKey="timestamp"
          tickFormatter={formatTime}
          type="number"
          domain={['auto', Date.now() + 5 * 60 * 1000]}
        />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip labelFormatter={formatTime} />
        <Legend />
        <Area 
          type="monotone" 
          dataKey="stressIndex" 
          stroke="#ffc658" 
          fillOpacity={1} 
          fill="url(#colorStressIndex)" 
          connectNulls={true}
        />
        <Area 
          type="monotone" 
          dataKey="readiness" 
          stroke="#0088FE" 
          fillOpacity={1} 
          fill="url(#colorReadiness)" 
          connectNulls={true}
        />
        <Area
          type="monotone"
          dataKey="sd1sd2Ratio"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorSd1sd2Ratio)"
          connectNulls={true}
        />
      </AreaChart>
    </ResponsiveContainer>

          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default HeartRateMonitor;
