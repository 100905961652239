import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import FileSaver from 'file-saver';
import { HRVVisualization, HRVResult, convertResult } from './HRVVisualization';

interface ResultType {
  success?: boolean;
  message?: string;
  downloadLink?: string;
  data?: any;
}

const Result: React.FC<{ result: ResultType }> = ({ result }) => {
  const [hrvResults, setHrvResults] = useState<HRVResult[]>([]); // State for HRV results
  const [loading, setLoading] = useState(true); // State for loading status

  useEffect(() => {
    const fetchAndConvertData = async () => {
      setLoading(true);
      try {
        const results = await convertResult(result);
        setHrvResults(results);
      } catch (error) {
        console.error('Error converting HRV data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndConvertData();
  }, [result]);

  const handleDownload = async (result: ResultType) => {
    if (!result) {
      console.error('No result available for download.');
      return;
    }

    if (result.downloadLink) {
      try {
        const response = await fetch(result.downloadLink);
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }

        const content = await response.json();
        console.log('Fetched Content:', content);

        const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' });
        FileSaver.saveAs(blob, 'HRV_result.json');
      } catch (error) {
        console.error('Error fetching the download link:', error);
      }
    } else {
      const blob = new Blob([JSON.stringify(result, null, 2)], { type: 'application/json' });
      FileSaver.saveAs(blob, 'HRV_result.json');
    }
  };

  const preStyle: React.CSSProperties = {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
  };

  return (
    <div>
      <h2>HRV Result</h2>
      <pre style={preStyle}>{JSON.stringify(result, null, 2)}</pre>
      <Button variant="contained" color="primary" onClick={() => handleDownload(result)}>
        Download Result
      </Button>
      {loading ? (
        <p>Loading HRV Visualization...</p>
      ) : hrvResults.length > 0 ? (
        <HRVVisualization results={hrvResults} />
      ) : (
        <p>No HRV data available for visualization.</p>
      )}
    </div>
  );
};


export type { ResultType };
export { Result };
