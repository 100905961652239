import React, { useState } from 'react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import db from './config/firebase_config.js';

import {
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  Alert,
  Grid,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DiscordIcon from '@mui/icons-material/Chat';

const FormComponent = () => {
  const [email, setEmail] = useState('');
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const documentId = `email-${new Date().getTime()}`;
        const docRef = doc(db, 'emails', documentId);
        await setDoc(docRef, {
          email: email,
          createdAt: serverTimestamp(),
        });
        setEmail('');
        setPopup(true);
        setError('');
      } catch (e) {
        setError('An error occurred while subscribing. Please try again.');
        console.error('Error adding document: ', e);
      }
    } else {
      setError('Please enter a valid email address.');
    }
  };

  return (
    <Box
      sx={{
        mt: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        px: 2,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 4,
          maxWidth: 500,
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          backdropFilter: 'blur(10px)',
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Let's Stay in Touch!
        </Typography>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                value={email}
                name="email"
                label="Email Address"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<MailOutlineIcon />}
              >
                Subscribe for Newsletter and Beta Access
              </Button>
            </Grid>
          </Grid>
        </form>
        {popup && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Thank you for subscribing!
          </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box mt={4} textAlign="center">
          <Typography variant="h6" gutterBottom>
            Join Our Community!
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DiscordIcon />}
            component={Link}
            href="https://discord.gg/4HzV5EFAEN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Our Discord Channel
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default FormComponent;
