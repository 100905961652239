import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth, googleProvider } from './config/firebase_config';
import { signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Header.css';

function SignIn() {
  const [user] = useAuthState(auth);

  const handleLogout = () => {
    auth.signOut();
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  return (
    <>
      {user ? (
        <>
          <span className="user-name">{user?.displayName}</span>
          <button className="logout-button" onClick={handleLogout}>Log out</button>
        </>
      ) : (
        <button className="signin-button" onClick={signInWithGoogle}>Sign In</button>
      )}
    </>
  );
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <ul className="header-buttons">
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/live" onClick={toggleMenu}>Live Demo</Link></li>
          <li><Link to="/hrv-calculation-demo" onClick={toggleMenu}>HRV Calculator</Link></li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          <li><Link to="/open-hrv-team" onClick={toggleMenu}>Team</Link></li>
          <li><Link to="/hrv-datasets" onClick={toggleMenu}>Datasets</Link></li>
          <li><Link to="/wearables-we-support" onClick={toggleMenu}>Wearables</Link></li>
          <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li>
          <li><SignIn /></li>
        </ul>
        <div className="menu-close" onClick={toggleMenu}>
          <FaTimes />
        </div>
      </nav>
      <div className="menu-toggle" onClick={toggleMenu}>
        <FaBars />
      </div>
    </header>
  );
};

export default Header;
export { SignIn };