import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import { collection, addDoc } from "firebase/firestore";
import db from '../config/firebase_config.js';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ open, onClose }) => {
  const [feedbackText, setFeedbackText] = useState<string>('');

  const handleSubmit = async () => {
    if (feedbackText) {
      try {
        await addDoc(collection(db, "messages"), { message: feedbackText });
        setFeedbackText('');
        onClose();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>We Value Your Feedback</DialogTitle>
      <DialogContent>
        <DialogContentText>Please let us know your thoughts on the app.</DialogContentText>
        <TextField
          fullWidth
          label="Your Feedback"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
